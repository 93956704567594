<template>
  <div class="pb-2 max-w-screen-md mx-auto">
    <div
      class="bg-bg_alt_color text-text_color rounded-2xl shadow-item overflow-hidden"
    >
      <div class="w-full">
        <NuxtImg
          provider="cacheIpx"
          preset="modified"
          loading="lazy"
          :src="offer.image"
          :alt="t('offers')"
          width="170"
          height="43"
          sizes="sm:256px md:384px lg:768px"
          class="w-full rounded-t-2xl aspect-[4] object-cover"
        />
      </div>
      <div class="w-full flex justify-start items-center flex-wrap pt-1 mb-5">
        <div
          class="flex-shrink-0 h-9 ltr:mr-auto rtl:ml-auto ltr:ml-2 rtl:mr-2 mb-1"
        >
          <MevcutSubmitButton
            v-if="offer.store?.cart_visible"
            :title="t('order_now')"
            radius="rounded-lg"
            padding="px-1 py-2 sm:px-2"
            color="bg-button_color"
            text-color="text-text_alt_color text-xs sm:text-base"
            type="button"
            :loading="loading"
            @click="addOfferLocal()"
          />
          <MevcutSubmitButton
            v-else
            :title="t('contact_via_whatsApp', { to: t('cart') })"
            radius="rounded-lg"
            padding="px-1 py-2 sm:px-2"
            color="bg-button_color"
            text-color="text-text_alt_color text-xs sm:text-base"
            type="button"
            :loading="false"
            @click="useContactViaWhatsAppForMaterials(undefined, offer)"
          />
        </div>

        <div
          v-if="offer.store?.price_visible"
          class="flex-auto flex items-end my-1"
        >
          <div class="line-through leading-none ltr:ml-4 rtl:mr-4">
            <small :dir="offer.currencyIsRtl ? 'rtl' : 'ltr'">
              {{ offer.originalPrice }}
            </small>
          </div>

          <div
            :dir="offer.currencyIsRtl ? 'rtl' : 'ltr'"
            class="font-medium text-lg leading-none mx-3"
          >
            <span> {{ offer.price }} </span>
          </div>

          <div
            class="ltr:ml-auto rtl:mr-auto bg-nav_color text-xs text-text_alt_color px-4 py-1 ltr:border-r-nav_color ltr:rounded-l-full rtl:border-l-nav_color rtl:rounded-r-full"
          >
            {{ t('discount_percent', { value: parseInt(offer.discountRate) }) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useToast } from 'vue-toastification'
import type { GroupOffer } from '~~/composables/useMenuModel'
const props = withDefaults(
  defineProps<{
    offer: GroupOffer
  }>(),
  {}
)

const { t } = useI18n()
const loading = ref(false)

async function addOfferLocal() {
  const { addOffer } = useCartStore()
  const toast = useToast()

  if (!loading.value) {
    loading.value = true
    if (await addOffer(props.offer.id, props.offer.store_id)) {
      toast.success(t('add_done', { to: t('to'), name: t('cart') }), {
        timeout: 1000
      })
    }
    loading.value = false
  }
}
</script>
